<template>
  <div class="container confirm-order" fluid>
    <card-header-body ref="header" :node="headerTitle" />
    <div id="content-scroll" class="content-scroll">
      <div id="confirm-order">
        <b-row
          class="body"
          :class="searchOrder.profileId ? 'd-block' : 'd-flex'"
        >
          <div
            class="none-padding"
            :class="searchOrder.profileId ? 'padding-order' : 'col-xl-6 col-12'"
          >
            <div
              v-if="isNextConfirmOrder"
              class="menu text-left menu--inner text-title f-w6"
            >
              以下の内容でSuperオファーを確定しました
            </div>
            <template v-if="searchOrder.designation_user_id">
              <div class="table-content table-content--inner">
                <div class="content">
                  <div class="d-flex align-items-center content--border">
                    <div class="icon-check">
                      <img
                        :src="
                          require('@/assets/image/create_order/calendar.svg')
                        "
                        alt="Time"
                      />
                    </div>
                    <div class="content--text text-right d-flex">
                      <span
                        class="content--text__font content--text__inner f-w3"
                        >ラウンド日時/お客様人数</span
                      >
                      <span class="f-w6 value"
                        >{{
                          searchOrder.scheduled_meeting_datetime &&
                            $dayjs(
                              searchOrder.scheduled_meeting_datetime
                            ).format("M月D日 H")
                        }}時頃 ～ {{ searchOrder.male_participants }}人</span
                      >
                    </div>
                  </div>
                </div>
                <hr class="solid" />
                <div class="content">
                  <div class="d-flex align-items-center content--border">
                    <div class="icon-check">
                      <img
                        :src="require('@/assets/image/create_order/file.svg')"
                        alt="calendar"
                      />
                    </div>
                    <div class="content--text text-right d-flex">
                      <span
                        class="content--text__font content--text__inner f-w3"
                        >ピックアップエリア／プラン</span
                      >
                      <span class="f-w6 value"
                        >{{ getAreaName() }}<span v-if="getAreaName()">／</span
                        >{{ getPlanName() }}</span
                      >
                    </div>
                  </div>
                </div>
                <hr class="solid" />
                <div class="content">
                  <div class="d-flex align-items-center content--border">
                    <div class="icon-check">
                      <img
                        :src="require('@/assets/image/create_order/direct.svg')"
                        alt="road"
                      />
                    </div>
                    <div class="content--text text-right d-flex">
                      <span
                        class="content--text__font content--text__inner f-w3"
                        >ゴルフ場／予約状況</span
                      >
                      <span class="f-w6 value"
                        >{{ getGolfCourseName() }}／{{ getReservation() }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="table-content table-content--inner">
                <div class="content">
                  <div class="d-flex align-items-center content--border">
                    <div class="icon-check">
                      <img
                        :src="
                          require('@/assets/image/create_order/calendar.svg')
                        "
                        alt="Time"
                      />
                    </div>
                    <div class="content--text text-right d-flex">
                      <span
                        class="content--text__font content--text__inner f-w3"
                        >ラウンド日時/お客様人数</span
                      >
                      <span class="f-w6 value"
                        >{{
                          searchOrder.scheduled_meeting_datetime &&
                            $dayjs(
                              searchOrder.scheduled_meeting_datetime
                            ).format("M月D日 H")
                        }}時頃 ～ {{ searchOrder.male_participants }}人</span
                      >
                    </div>
                  </div>
                </div>
                <hr class="solid" />
                <div class="content">
                  <div class="d-flex align-items-center content--border">
                    <div class="icon-check">
                      <img
                        :src="require('@/assets/image/create_order/file.svg')"
                        alt="calendar"
                      />
                    </div>
                    <div class="content--text text-right d-flex">
                      <span
                        class="content--text__font content--text__inner f-w3"
                        >ピックアップエリア／プラン</span
                      >
                      <span class="f-w6 value"
                        >{{ getAreaName() }}<span v-if="getAreaName()">／</span
                        >{{ getPlanName() }}</span
                      >
                    </div>
                  </div>
                  <hr v-if="!searchOrder.profileId" class="solid" />
                  <div v-if="!searchOrder.profileId" class="content" id="user">
                    <div class="d-flex align-items-center content--border">
                      <div class="icon-check">
                        <img
                          :src="require('@/assets/image/create_order/user.svg')"
                          alt="Note"
                        />
                      </div>
                      <div class="content--text text-right d-flex">
                        <span
                          class="content--text__font content--text__inner f-w3"
                          >女性Superオファー人数</span
                        >
                        <span class="f-w6 value"
                          >{{ searchOrder.female_participants }}人</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="solid" />
                <div class="content">
                  <div class="d-flex align-items-center content--border">
                    <div class="icon-check">
                      <img
                        :src="require('@/assets/image/create_order/direct.svg')"
                        alt="road"
                      />
                    </div>
                    <div class="content--text text-right d-flex">
                      <span
                        class="content--text__font content--text__inner f-w3"
                        >ゴルフ場／予約状況</span
                      >
                      <span class="f-w6 value mb-0"
                        >{{ getGolfCourseName() }}/</span
                      >
                      <p class="f-w6 value">{{ getReservation() }}</p>
                    </div>
                  </div>
                  <hr v-if="!searchOrder.profileId" class="solid" />
                  <div
                    v-if="!searchOrder.profileId"
                    class="content"
                    id="trophy"
                  >
                    <div class="d-flex align-items-center">
                      <div class="icon-check">
                        <img
                          :src="
                            require('@/assets/image/create_order/trophy.svg')
                          "
                          alt="control"
                        />
                      </div>
                      <div class="content--text text-right d-flex">
                        <span
                          class="content--text__font content--text__inner f-w3"
                          >ゴルフレベル／ランク</span
                        >
                        <span class="f-w6 value mb-0"
                          >{{ getGolfRankName() }}/</span
                        >
                        <p class="f-w6 value">{{ getPregoRankName() }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <b-row
            v-if="isNextConfirmOrder && isExpand === false"
            class="row-expand w-100"
          >
            <b-button @click="changeExpand()" class="btn-expand f-w3">
              詳細
              <b-icon icon="chevron-down"></b-icon>
            </b-button>
          </b-row>
          <div
            v-if="isNextConfirmOrder === false || isExpand"
            class="none-padding"
            :class="searchOrder.profileId ? 'padding-order' : 'col-xl-6 col-12'"
          >
            <hr
              data-content="プラン詳細"
              v-if="!searchOrder.profileId"
              class="hr-text"
            />
            <div
              class="menu-golf"
              v-if="(searchOrder.attitude_ids || []).length"
            >
              <div class="menu text-left menu--inner menu-text--inner">
                ゴルフシチュエーション
              </div>
              <div class="btn-style text-left f-w3">
                <button
                  v-for="(item, index) in getButtonByProperty(
                    'attitudes',
                    'attitude'
                  )"
                  :key="index"
                  type="button"
                  class="btn-check"
                  :id="index"
                >
                  {{ item.name }}
                </button>
              </div>
            </div>
            <!-- <div
            class="menu-cast"
            v-if="(searchOrder.preferred_age_ids || []).length"
          >
            <div class="menu text-left menu--inner menu-text--inner">
              ゴルフシチュエーション
            </div>
            <div class="btn-style text-left f-w3">
              <button
                v-for="(item, index) in getButtonByProperty(
                  'preferredAges',
                  'preferred_age'
                )"
                :key="index"
                type="button"
                class="btn-check"
                :id="index"
              >
                {{ item.name }}
              </button>
            </div>
          </div>
          <div class="menu-cast" v-if="(searchOrder.appearance_ids || []).length">
            <div class="menu text-left menu--inner menu-text--inner">
              タイプ
            </div>
            <div class="btn-style text-left f-w3">
              <button
                v-for="(item, index) in getButtonByProperty(
                  'appearances',
                  'appearance'
                )"
                :key="index"
                type="button"
                class="btn-check"
                :id="index"
              >
                {{ item.name }}
              </button>
            </div>
          </div>
          <div class="menu-cast" v-if="(searchOrder.figure_ids || []).length">
            <div class="menu text-left menu--inner menu-text--inner">
              スタイル
            </div>
            <div class="btn-style text-left f-w3">
              <button
                v-for="(item, index) in getButtonByProperty('figures', 'figure')"
                :key="index"
                type="button"
                class="btn-check"
                :id="index"
              >
                {{ item.name }}
              </button>
            </div>
          </div>
          <div
            class="menu-cast"
            v-if="(searchOrder.other_preference_ids || []).length"
          >
            <div class="menu text-left menu--inner menu-text--inner">
              その他
            </div>
            <div class="btn-style text-left f-w3">
              <button
                v-for="(item, index) in getButtonByProperty(
                  'otherPreferences',
                  'other_preference'
                )"
                :key="index"
                type="button"
                class="btn-check"
                :id="index"
              >
                {{ item.name }}
              </button>
            </div>
          </div> -->
            <div
              class="menu-golf"
              v-if="getAllData.length > 0 || searchOrder.single_participant"
            >
              <div class="menu text-left menu--inner menu-text--inner">
                キャストタイプ
              </div>
              <div class="btn-style text-left f-w3">
                <button
                  v-for="(item, index) in getAllData"
                  :key="index"
                  type="button"
                  class="btn-check"
                  :class="item.name == TEXT_ORDER ? 'btn-length' : ''"
                  :id="index"
                >
                  {{ item.name }}
                </button>
              </div>
            </div>
            <template v-if="searchOrder.single_participant">
              <!-- <hr data-content="男性のSuperオファーに対して" class="hr-text" /> -->
              <div class="d-flex justify-content-start w-100">
                <button
                  v-if="searchOrder.single_participant"
                  class="btn-check btn-ok f-w3 fs-12"
                >
                  一人参加OK
                </button>
              </div>
              <hr class="solid part" />
            </template>
            <div v-if="searchOrder.request" class="description w-100">
              <div class="text-left">
                <div class="description-title f-w6">ご要望</div>
                <div class="text-request f-w3">
                  {{ searchOrder.request }}
                </div>
              </div>
            </div>
          </div>
        </b-row>
        <template v-if="isNextConfirmOrder === false || isExpand">
          <div class="border"></div>
          <div class="content-total d-flex align-items-center f-w6">
            <div class="content-total__label">合計</div>
            <div class="content-total__value">{{ getTotal(1) }}P</div>
          </div>
          <b-row
            v-if="isNextConfirmOrder && isExpand"
            class="row-expand bottom w-100"
          >
            <b-button @click="changeExpand()" class="btn-expand f-w3">
              たたむ
              <b-icon icon="chevron-up"></b-icon>
            </b-button>
          </b-row>
          <div v-if="!isNextConfirmOrder" class="agree">
            <b-form-checkbox v-model="isConfirm" class="f-w3 check-box" />
            <div
              class="check-box text f-w3"
              style="outline: none; box-shadow: none"
              v-b-modal.confirm-info__popup
            >
              注意事項に同意する
            </div>
          </div>
        </template>
        <div>
          <div class="btn-inner">
            <button
              :disabled="isConfirm === false && !isNextConfirmOrder"
              @click="createOrder()"
              class="btn-submit f-w3"
            >
              {{
                isNextConfirmOrder
                  ? "ホーム画面に戻る"
                  : "Superオファーを確定する"
              }}
            </button>
          </div>
        </div>
      </div>
      <b-modal class="modal" id="confirm-info__popup" title="募集内容の確認">
        <confirmOrderPopup-body />
      </b-modal>
      <ModalLayout ref="modal-confirm-take-charge">
        <p class="modal-logout-title f-w3">
          キャンセル料が発生する期間の募集となりますがよろしいですか？
        </p>
        <p style="font-size: 14px; color: #6e6e6e">
          ※キャンセル料の発生はマッチングが完了したものに限ります
        </p>
        <div class="d-flex justify-content-center">
          <button @click.prevent="confirmTakeCharge" class="btn-logout-rtl">
            はい
          </button>
          <button @click="cancelTakeCharge" class="btn-cancel-logout-rtl">
            いいえ
          </button>
        </div>
      </ModalLayout>
    </div>
    <CardFooter ref="footer" />
  </div>
</template>

<script>
import CardHeader from "../../components/CardHeader";
import CardFooter from "../../components/CardFooter";
import ConfirmOrderPopup from "@/components/ConfirmOrderPopup";
import { mapGetters } from "vuex";
import { TEXT_ORDER } from "@/utils/const";
import {
  RESERVATION_STATUS,
  // MESSAGES,
  MIN_WIDTH_PC
  // RATIO_GOLF_RANK
} from "@/utils/const";
import { numberWithCommas } from "@/utils/convert";
import { SYSTEM_ROOM_TYPE } from "@/constants/message";

export default {
  name: "ConfirmOrder",
  props: {
    isPrevious: {
      type: Boolean,
      default: false
    },
    profileId: {
      type: [String, Number]
    }
  },
  components: {
    "confirmOrderPopup-body": ConfirmOrderPopup,
    "card-header-body": CardHeader,
    CardFooter
  },
  metaInfo() {
    return {
      title: "募集内容の確認",
      titleTemplate: "%s | PreGo"
    };
  },
  data() {
    return {
      TEXT_ORDER: TEXT_ORDER,
      headerTitle: {
        text: "募集内容の確認",
        isShowTitle: true,
        isShowLogo: false
      },
      isConfirm: false,
      isNextConfirmOrder: false,
      isExpand: false
    };
  },
  methods: {
    changeExpand() {
      this.isExpand = !this.isExpand;
    },
    confirmCreateOrder() {
      if (this.$route.query.create) {
        this.$router.push({ name: "Home" });
        return;
      }
      const oneDay = 1000 * 60 * 60 * 24;
      let nowDate = new Date();
      let scheduleDate = this.searchOrder.scheduled_meeting_datetime;
      if (
        Math.round(scheduleDate.getTime() / oneDay) -
          Math.round(nowDate.getTime() / oneDay) <
        7
      ) {
        this.$refs["modal-confirm-take-charge"].openModal();
      } else {
        if (
          this.$route.params.id &&
          this.$route.name === "MenEditConfirmOrder"
        ) {
          this.$router.push({
            name: "MenEditOrderStepTwo",
            params: {
              id: this.$route.params.id
            }
          });
        } else {
          this.$router.push({ name: "MenCreateOrderStepTwo" });
        }
      }
    },
    cancelOrder() {
      this.$refs["modal-confirm-create-order"].closeModal();
    },
    confirmTakeCharge() {
      this.$refs["modal-confirm-take-charge"].closeModal();
      if (this.$route.params.id && this.$route.name === "MenEditConfirmOrder") {
        this.$router.push({
          name: "MenEditOrderStepTwo",
          params: {
            id: this.$route.params.id
          }
        });
      } else {
        this.$router.push({ name: "MenCreateOrderStepTwo" });
      }
    },
    cancelTakeCharge() {
      this.$refs["modal-confirm-take-charge"].closeModal();
    },
    async createOrder() {
      // this.$refs["modal-confirm-create-order"].closeModal();
      if (this.isNextConfirmOrder) {
        this.$router.push({ name: "Home" });
        return;
      }
      this.$root.$refs.loading.start();
      await this.$store.dispatch("payment-stripe/getCreditCard");
      const creditCard = this.$store.getters["payment-stripe/cardInfo"];
      if (!creditCard) {
        // from create new normal order
        let castId = localStorage.getItem("direct_order");
        if (castId) {
          this.$router.push({
            name: "MenRegisterCardCredit",
            params: { castId: castId }
          });
        } else {
          this.$router.push({
            name: "MenRegisterCardCredit",
            params: { profileId: this.searchOrder.profileId }
          });
        }
        return;
      }
      let params = {
        ...this.searchOrder,
        user_id: this.user.user_id,
        golf_course: this.searchOrder.golf_course_new,
        female_golf_rank_ids: this.searchOrder.female_golf_ranks,
        female_prego_rank_ids: this.searchOrder.female_prego_ranks,
        scheduled_meeting_datetime: this.$dayjs(
          this.searchOrder.scheduled_meeting_datetime
        ).format("YYYY-MM-DD HH:mm:00")
      };
      if (this.searchOrder.profileId) {
        params = {
          ...params,
          designation_user_id: Number(this.searchOrder.profileId)
        };
      }
      let url = "orderManagement/createOrder";
      let castId = localStorage.getItem("direct_order");
      if (this.$route.params.id) {
        url = "orderManagement/editOrder";
        params = {
          ...params,
          ad_id: this.$route.params.id
        };
        this.headerTitle.backTo = "/my-page/orders";
      } else if (castId) {
        // create direct order success: click button back => go to cast profile
        this.headerTitle.backTo = `/profile/${castId}`;
      } else {
        this.headerTitle.backTo = "/create-order";
      }

      delete params.female_golf_ranks;
      delete params.female_prego_ranks;

      this.$store
        .dispatch(url, params)
        .then(response => {
          this.isNextConfirmOrder = true;
          this.$root.$refs.loading.finish();
          let targetEle = document.getElementById("confirm-order");
          let pos = targetEle.style.position;
          let top = targetEle.style.top;
          targetEle.style.position = "relative";
          targetEle.style.top = "-40px";
          targetEle.scrollIntoView({ behavior: "smooth", block: "start" });
          targetEle.style.position = pos;
          targetEle.style.top = top;
          localStorage.setItem("createOrder", 1);
          let data = {
            hiddenUserIds: {
              [this.user.user_id]: true,
              [0]: true
            },
            userIds: [this.user.user_id, 0],
            adId: null,
            type: SYSTEM_ROOM_TYPE
          };
          this.$store
            .dispatch("firebase/createRoom", data)
            .then(result => {
              let dataMessage = {
                roomId: result,
                readUsers: {
                  [0]: true,
                  [this.user.user_id]: false
                },
                message: {
                  // golf_course_name: this.getGolfCourseName(),
                  // scheduled_meeting_date: this.$dayjs(
                  //   this.searchOrder.scheduled_meeting_datetime
                  // ).format("YYYY/MM/DD"),
                  // scheduled_meeting_time: this.$dayjs(
                  //   this.searchOrder.scheduled_meeting_datetime
                  // ).format("HH:mm"),
                  // participants:
                  //   params.male_participants + params.female_participants,
                  // plan: this.getPlanName(),
                  url: `/create-order/order-detail/${response.data.ad_id}`
                }
              };
              this.$store.dispatch("firebase/createSystemMessage", dataMessage);
            })
            .catch(error => {
              throw error;
            });
          if (response?.message) {
            this.$toast(response?.message, "通知", "danger");
            return;
          }
          this.$toast("Superオファーが完了しました", "通知", "success");
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
        });
    },
    getAreaName() {
      return this.searchOrder.golf_area_id
        ? this.commonData.golfAreas &&
            this.commonData.golfAreas?.find(
              el => el.golf_area_id === this.searchOrder.golf_area_id
            ).name
        : "";
    },
    getPlanName() {
      return this.searchOrder.plan_id
        ? this.commonData.plans &&
            this.commonData.plans?.find(
              el => el.plan_id === this.searchOrder.plan_id
            ).name
        : "";
    },
    getGolfCourseName() {
      if (this.searchOrder.golf_course_id) {
        return (
          (this.commonData.golfCourses &&
            this.commonData.golfCourses?.find(
              el => el.golf_course_id === this.searchOrder.golf_course_id
            ).name) ||
          ""
        );
      }
      return this.searchOrder.golf_course_new;
    },
    getGolfRankName() {
      let name = "";
      const arrGolfRank = this.searchOrder.female_golf_ranks;
      this.commonData.femaleGolfRanks?.forEach(element => {
        if (arrGolfRank[0] && element.female_golf_rank_id === arrGolfRank[0]) {
          name += element.name + "、";
        } else if (
          arrGolfRank[1] &&
          element.female_golf_rank_id === arrGolfRank[1]
        ) {
          name += element.name + "、";
        }
      });
      return name ? name.substring(0, name.length - 1) : "";
    },
    getPregoRankName() {
      let name = "";
      const arrPregoRank = this.searchOrder.female_prego_ranks;
      this.commonData.femalePregoRanks?.forEach(element => {
        if (
          arrPregoRank[0] &&
          element.female_prego_rank_id === arrPregoRank[0]
        ) {
          switch (element.name) {
            case "WHITE":
              name += "プレ友（無料）" + "、";
              break;
            case "GREEN":
              name += "新人" + "、";
              break;
            case "BLUE":
              name += "優良" + "、";
              break;
            case "RED":
              name += "超優良" + ", ";
              break;
            default:
              if (element.name === "プレ友") {
                name += element.name + "（無料）、";
              } else {
                name += element.name + "、";
              }
              break;
          }
        } else if (
          arrPregoRank[1] &&
          element.female_prego_rank_id === arrPregoRank[1]
        ) {
          switch (element.name) {
            case "WHITE":
              name += "新規" + "、";
              break;
            case "GREEN":
              name += "通常" + "、";
              break;
            case "BLUE":
              name += "優良" + "、";
              break;
            case "RED":
              name += "超優良" + "、";
              break;
            default:
              name += element.name + "、";
              break;
          }
        }
      });
      return name ? name.substring(0, name.length - 1) : "";
    },
    getReservation() {
      return RESERVATION_STATUS[this.searchOrder.reservation_status];
    },
    getTotal(type) {
      // const plan = this.commonData.plans?.find(
      //   el => el.plan_id === this.searchOrder.plan_id
      // );

      // if (!plan) return;
      // let moneyOffer = 0;
      // if(localStorage.getItem("direct_order")) {
      //   moneyOffer = 10000;
      // }
      // // transportation cost
      // let transportationCost = 0;
      // if (plan.meeting_method === 2) {
      //   transportationCost =
      //     Number(plan.transportation_costs) *
      //     Number(this.searchOrder.female_participants);
      // }

      // // female golf rank 30 minutes pay
      // let costGolfRank = Number.MAX_SAFE_INTEGER;
      // (this.commonData.femaleGolfRanks || []).forEach(el => {
      //   this.searchOrder?.female_golf_ranks.forEach(id => {
      //     if (
      //       el.female_golf_rank_id === id &&
      //       costGolfRank > Number(el["30_minutes_pay"])
      //     ) {
      //       costGolfRank = Number(el["30_minutes_pay"]);
      //     }
      //   });
      // });

      // // female prego rank ratio
      // let ratioReward = Number.MAX_SAFE_INTEGER;
      // (this.commonData.femalePregoRanks || []).forEach(el => {
      //   this.searchOrder?.female_prego_ranks.forEach(id => {
      //     if (
      //       el.female_prego_rank_id === id &&
      //       ratioReward > Number(el["reward_ratio"])
      //     ) {
      //       ratioReward = Number(el["reward_ratio"]);
      //     }
      //   });
      // });

      // // menu price per person
      // let menuPricePerPerson =
      //   Number(plan.binding_hours) *
      //   costGolfRank *
      //   RATIO_GOLF_RANK *
      //   ratioReward;

      // // menu price
      // let menuPrice = menuPricePerPerson * this.searchOrder.female_participants + moneyOffer;

      // total amount
      let totalAmount = Number(localStorage.getItem("totalAmount"));
      if (type == 1) {
        return numberWithCommas(totalAmount);
      }
      return totalAmount;
    },
    getButtonByProperty(property, name) {
      const arr = [];
      (this.commonData[property] || []).forEach(el => {
        this.searchOrder[name + "_ids"].forEach(id => {
          if (el[name + "_id"] === id) {
            arr.push(el);
          }
        });
      });
      return arr;
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      searchOrder: "orderManagement/searchOrder",
      commonData: "common/commonTutorials"
    }),
    getAllData() {
      let arr = [];
      arr = [
        ...this.getButtonByProperty("preferredAges", "preferred_age"),
        ...this.getButtonByProperty("appearances", "appearance"),
        ...this.getButtonByProperty("figures", "figure"),
        ...this.getButtonByProperty("otherPreferences", "other_preference")
      ];
      return arr;
    }
  },
  mounted() {
    const maxWidth = Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
    if (maxWidth > MIN_WIDTH_PC) {
      this.isExpand = true;
    }
    // let targetEle;
    // if (this.$route.params.id) {
    //   targetEle = document.getElementById(this.$route.params.id);
    // } else {
    //   targetEle = document.getElementById("confirm-order");
    // }
    // let pos = targetEle.style.position;
    // let top = targetEle.style.top;
    // targetEle.style.position = "relative";
    // targetEle.style.top = "-50px";
    // targetEle.scrollIntoView({ behavior: "smooth", block: "start" });
    // targetEle.style.top = top;
    // targetEle.style.position = pos;
  },
  created() {
    if (!this.searchOrder.scheduled_meeting_datetime) {
      if (this.$route.params.id) {
        this.$router.push({
          name: "MenEditOrderStepOne",
          params: { id: this.$route.params.id }
        });
      } else {
        this.$router.push({
          name: "MenCreateOrderStepOne"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/men/confirmOrder.scss";
@import "~@/assets/scss/_fontFamily.scss";

.content-scroll {
  // overflow-y: scroll;
  padding-top: 40px;
  padding-bottom: 40px;
  overflow-x: hidden;
  // height: calc(100vh - 108px);
}

@media screen and (min-width: 1200px) {
  .content-scroll {
    // height: calc(100vh - 152px);
  }
}
</style>
